import { NumberFormatBase, useNumericFormat } from "react-number-format";
import { utils } from "../utils/utils";

const NumericFormat = (props) => {
  const newProps = { ...props, value: utils.formatDecimal(props.value, props.decimalScale) };
  const { ...numberFormatBaseProps } = useNumericFormat(newProps);
  return <NumberFormatBase {...numberFormatBaseProps} />;
};

export default NumericFormat;
