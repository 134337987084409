import React, { Fragment, useState } from "react";
import NumericFormat from "../../../NumericFormat";
import {
  Button,
  Col,
  Form,
  FormGroup,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";
import {
  ACTIONS,
  useQuoteItems,
} from "../../../../providers/quoteItemsProvider";
import { quoteItemsApi } from "../../../../services/quoteItemServices";
import { utils } from "../../../../utils/utils";
import InformationModal from "../../../InformationModal";
import Loader from "../../../Loader";

const QuoteRecapResumeSection = ({ quoteItem }) => {
  const [, setQuoteItemsContext] = useQuoteItems();
  const [jobDetails] = useJobDetails();
  const calculations = utils.doQuoteItemCalculations(quoteItem, jobDetails.job);

  const isJobLocked = jobDetails.job.lockDate;

  const [loading, setLoading] = useState();

  const totalWeight = calculations.totalWeight || 0;
  const shopHours = calculations.shopHours || 0;
  const officeHours = calculations.officeHours || 0;
  const totalCost = calculations.totalCost || 0;
  const salePerLB = calculations.salePerLB || 0;
  const marginPercentAux =
    (calculations.marginAmount / calculations.salePrice) * 100 || 0;
  const [marginAmount, setMarginAmount] = useState(
    calculations.marginAmount || 0
  );
  const [marginPercent, setMarginPercent] = useState(
    marginPercentAux
  );
  const [salePrice, setSalePrice] = useState(calculations.salePrice || 0);
  const [unitPrice, setUnitPrice] = useState(calculations.unitPrice || 0);
  const [markupPercentage, setMarkupPercentage] = useState(
    quoteItem.margin || 0
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const onChangeMarginAmount = (v) => {
    const markupPercentageAux = totalCost ? (v * 100) / totalCost : 0;
    const salePriceAux = totalCost * (1 + markupPercentageAux / 100);
    const marginPercentAux = salePriceAux ? (v / salePriceAux) * 100 : 0;
    setMarginAmount(v);
    setMarginPercent(marginPercentAux);
    setMarkupPercentage(markupPercentageAux);
    setSalePrice(salePriceAux);
  };

  const onChangeMarkupPercentage = (v) => {
    const salePriceAux = totalCost * (1 + v / 100);
    const marginAmountAux = (totalCost * v) / 100;
    const marginPercentAux = salePriceAux
      ? (marginAmountAux / salePriceAux) * 100
      : 0;
    setSalePrice(salePriceAux);
    setMarginAmount(marginAmountAux);
    setMarginPercent(marginPercentAux);
    setMarkupPercentage();
  };

  const onChangeSalePrice = (v) => {
    const markupPercentageAux = totalCost ? 100 * (v / totalCost - 1) : 0;
    const marginAmount = (totalCost * markupPercentageAux) / 100;
    const unitPrice = v / quoteItem.quantity;
    const marginPercentAux = marginAmount ? (marginAmount / v) * 100 : 0;
    setMarginPercent(marginPercentAux);
    setMarginAmount(marginAmount);
    setMarkupPercentage(markupPercentageAux);
    setUnitPrice(unitPrice);
    setSalePrice(v);
  };

  const onChangeUnitPrice = (v) => {
    const salePriceAux = v * quoteItem.quantity;
    onChangeSalePrice(salePriceAux);
    setUnitPrice(v);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = utils.removeArrays(quoteItem);
      await quoteItemsApi.updateQuoteItem({
        ...data,
        margin: parseFloat(markupPercentage),
      });
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Update Quote Item",
        body: "Quote Item Updated Successfully.",
        onClose: () => {
          setInformationModal({ isOpen: false });
          setQuoteItemsContext({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      return setInformationModal({
        isOpen: true,
        title: "Update Quote Item",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loader size="sm" />
      ) : (
        <Form onSubmit={onSubmit} className="mt-4">
          <ListGroup>
            <ListGroupItem className="border-radius-0 d-flex justify-content-between align-items-center bg-lighter">
              <small className="font-weight-bold text-muted">Line Total</small>
              {isJobLocked ? null : (
                <Button
                  className="rounded ml-2"
                  size="sm"
                  color="success"
                  type="submit"
                >
                  Save
                </Button>
              )}
            </ListGroupItem>
            <ListGroupItem className="border-radius-0 d-flex justify-content-between align-items-center flex-wrap px-0 pb-0">
              <Col className="col-2">
                <FormGroup className="d-flex flex-column align-items-center">
                  <small className="font-weight-bold text-muted">Weight</small>
                  <div readOnly className="form-control border text-right">
                    {utils.formatWeight(totalWeight || 0)}
                  </div>
                </FormGroup>
              </Col>
              <Col className="col-2">
                <FormGroup className="d-flex flex-column align-items-center">
                  <small className="font-weight-bold text-muted">
                    Shop Hrs.
                  </small>
                  <div readOnly className="form-control border text-right">
                    {utils.formatHour(shopHours || 0)}
                  </div>
                </FormGroup>
              </Col>
              <Col className="col-2">
                <FormGroup className="d-flex flex-column align-items-center">
                  <small className="font-weight-bold text-muted">
                    Office Hrs.
                  </small>
                  <div readOnly className="form-control border text-right">
                    {utils.formatHour(officeHours || 0)}
                  </div>
                </FormGroup>
              </Col>
              <Col className="col-2">
                <FormGroup className="d-flex flex-column align-items-center">
                  <small className="font-weight-bold text-muted">Cost </small>
                  <div readOnly className="form-control border text-right">
                    {utils.formatCurrency(totalCost || 0)}
                  </div>
                </FormGroup>
              </Col>
              <Col className="col-2">
                <FormGroup className="d-flex flex-column align-items-center">
                  <small className="text-center font-weight-bold text-muted">
                    Cost Per Lb.
                  </small>
                  <div readOnly className="form-control border text-right">
                    {utils.formatCurrency(
                      totalWeight ? totalCost / totalWeight : 0
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col className="col-2">
                <FormGroup className="d-flex flex-column align-items-center">
                  <small className="text-center font-weight-bold text-muted">
                    Sale Per Lb.
                  </small>
                  <div readOnly className="form-control border text-right">
                    {utils.formatCurrency(salePerLB || 0)}
                  </div>
                </FormGroup>
              </Col>
              <Col className="col-2">
                <FormGroup className="d-flex flex-column align-items-center">
                  <small className="text-center font-weight-bold text-muted">
                    Margin Percent
                  </small>
                  <NumericFormat
                    required={true}
                    maxLength={20}
                    decimalScale={2}
                    allowNegative={false}
                    className={`form-control border text-right ${
                      marginPercent >= 20 ? "text-success" : "text-danger"
                    }`}
                    value={marginPercent || 0}
                    readOnly={true}
                    thousandSeparator={true}
                    prefix={""}
                    suffix={"%"}
                  />
                </FormGroup>
              </Col>
              <Col className="col-2">
                <FormGroup className="d-flex flex-column align-items-center">
                  <small className="font-weight-bold text-muted">
                    Margin Amount
                  </small>
                  <NumericFormat
                    required={true}
                    maxLength={20}
                    allowNegative={false}
                    decimalScale={2}
                    className="form-control border text-right"
                    value={marginAmount || 0}
                    readOnly={isJobLocked}
                    thousandSeparator={true}
                    onFocus={() => isJobLocked || onChangeMarginAmount(0)}
                    onValueChange={(e) => onChangeMarginAmount(e.value)}
                    prefix={"$"}
                  />
                </FormGroup>
              </Col>
              <Col className="col-2">
                <FormGroup className="d-flex flex-column align-items-center">
                  <small className="font-weight-bold text-muted">
                    Markup Percentage
                  </small>
                  <NumericFormat
                    className="form-control border text-right"
                    value={markupPercentage || 0}
                    readOnly={isJobLocked}
                    thousandSeparator={true}
                    decimalScale={2}
                    prefix={""}
                    suffix={"%"}
                    onFocus={() => isJobLocked || onChangeMarkupPercentage(0)}
                    onValueChange={(e) => onChangeMarkupPercentage(e.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="col-2">
                <FormGroup className="d-flex flex-column align-items-center">
                  <small className="font-weight-bold text-muted">
                    Unit Price
                  </small>
                  <NumericFormat
                    className="form-control border  text-right "
                    value={unitPrice || 0}
                    readOnly={isJobLocked}
                    thousandSeparator={true}
                    decimalScale={4}
                    prefix={"$"}
                    onFocus={() => isJobLocked || onChangeUnitPrice(0)}
                    onValueChange={(e) => onChangeUnitPrice(e.value)}
                  />
                </FormGroup>
              </Col>
              <Col className="col-2">
                <FormGroup className="d-flex flex-column align-items-center">
                  <small className="font-weight-bold text-muted">
                    Sale Price
                  </small>
                  <NumericFormat
                    required={true}
                    maxLength={20}
                    allowNegative={false}
                    decimalScale={2}
                    className="form-control border text-right"
                    value={salePrice || 0}
                    readOnly={isJobLocked}
                    thousandSeparator={true}
                    prefix={"$"}
                    onFocus={() => isJobLocked || onChangeSalePrice(0)}
                    onValueChange={(e) => onChangeSalePrice(e.value)}
                  />
                </FormGroup>
              </Col>
            </ListGroupItem>
          </ListGroup>
          {informationModal.isOpen ? (
            <InformationModal
              title={informationModal.title}
              body={informationModal.body}
              onClose={() =>
                informationModal.onClose
                  ? informationModal.onClose()
                  : setInformationModal({ isOpen: false, title: "", body: "" })
              }
            />
          ) : null}
        </Form>
      )}
    </Fragment>
  );
};

export default QuoteRecapResumeSection;
