import React, { useEffect, useState } from "react";
import { Input, Table } from "reactstrap";
import { useCommonData } from "../../../../../providers/commonDataProvider";
import { useJobDetails } from "../../../../../providers/jobDetailsProvider";
import { useQuoteItems } from "../../../../../providers/quoteItemsProvider";
import { utils } from "../../../../../utils/utils";
import {
  useEvaOverride,
  ACTIONS as EVA_OVERRIDE_ACTIONS,
} from "../../../../../providers/evaOverrideProvider";
const FALSY_VALUES = [undefined, null];

const EstimatedActualLaborSection = () => {
  const [evaOverrideContext, setEvaOverrideContext] = useEvaOverride();
  const [commonDataContext] = useCommonData();
  const [jobDetails] = useJobDetails();
  const [quoteItemsContext] = useQuoteItems();

  const [laborCategories, setLaborCategories] = useState([]);

  useEffect(() => {
    const quoteItemLaborCategories = utils.reduceQuoteItemsLaborCategories(
      quoteItemsContext.quoteItems
    );

    const laborCategories = commonDataContext.quoteItemLaborCategories
      .filter(
        (laborCategory) =>
          !utils.laborCategoriesExcluded.includes(+laborCategory.code)
      )
      .map((laborCategory) => {
        const actualHoursOverride =
          evaOverrideContext.laborCategories[laborCategory.id];

        const taEntries = jobDetails.job.timeAttendanceLaborCodeHours.filter(
          (item) => item.laborCategoryId === laborCategory.id
        );

        const actualHours = !FALSY_VALUES.includes(actualHoursOverride)
          ? actualHoursOverride
          : taEntries.reduce((p, c) => p + c.reg + c.ovt, 0);

        const estimatedItem = quoteItemLaborCategories.find(
          (quoteItemLaborCategory) =>
            quoteItemLaborCategory.laborCategoryId === laborCategory.id
        );

        const fieldName = utils.getFieldNameToUseAsCostByHour(jobDetails.job);
        const estimatedHours = estimatedItem?.hours || 0;

        const totalCost =
          actualHours * laborCategory.costByHoursAndYear[fieldName];

        let costByHours = 0;
        let costHoursTotal = 0;
        if (estimatedItem) {
          costByHours =
            estimatedItem.laborCategory.costByHoursAndYear[fieldName];
          costHoursTotal = costByHours * estimatedHours;
        }

        return {
          ...laborCategory,
          actualHours,
          estimatedHours,
          totalCost,
          costByHours,
          costHoursTotal,
        };
      });
    setLaborCategories(laborCategories);
  }, [
    quoteItemsContext,
    commonDataContext,
    evaOverrideContext.laborCategories,
    jobDetails.job,
    jobDetails.job.timeAttendanceLaborCodeHours,
  ]);

  const actualTotals = laborCategories.reduce(
    (p, c) => {
      return {
        actualHours: p.actualHours + utils.formatDecimal(c.actualHours, 2, true),
        difference: p.difference + utils.formatDecimal((c.estimatedHours - c.actualHours), 2, true),
        totalCost: p.totalCost + utils.formatDecimal(c.totalCost, 2, true),
        totalCostEstimated: p.totalCostEstimated + utils.formatDecimal(c.costHoursTotal, 2, true),
      };
    },
    { actualHours: 0, difference: 0, totalCost: 0, totalCostEstimated: 0 }
  );

  const estimatedHoursTotal = laborCategories.reduce(
    (p, c) => p + c.estimatedHours,
    0
  );

  return (
    <>
      <h3 className="font-weight-bold">Estimated vs Actual</h3>
      <Table className="col-12 px-0 mt-4 border rounded mb-0">
        <thead className="small">
          <tr className="text-muted bg-lighter">
            <th width={"40%"} className="align-middle">
              Shop Labor Code/Cat.
            </th>
            <th width={"10%"} className="text-center">
              Estimated Hours
            </th>
            <th width={"10%"} className="text-center">
              Cost Estimated Hours
            </th>
            <th width={"10%"} className="text-center">
              Actual Hours
            </th>
            <th width={"15%"} className="text-center">
              Cost Actual Hours
            </th>
            <th width={"15%"} className="text-center">
              Difference
            </th>
          </tr>
        </thead>
        <tbody>
          {laborCategories
            .sort((x, y) => parseInt(x.code) - parseInt(y.code))
            .map((laborCategory) => {
              const laborCategoryCode = laborCategory.code
                ? `${laborCategory.code} - ${laborCategory.name}`
                : laborCategory.name;

              const difference =
                laborCategory.estimatedHours - laborCategory.actualHours;

              return (
                <tr key={laborCategory.code}>
                  <td className="py-0 text-truncate" width={"40%"}>
                    {laborCategoryCode}
                  </td>
                  <td className="py-0" width={"10%"}>
                    <div
                      readOnly
                      className="form-control border text-right m-1"
                    >
                      {utils.formatHour(laborCategory.estimatedHours)}
                    </div>
                  </td>
                  <td className="py-0" width={"10%"}>
                    <div
                      readOnly
                      className="form-control border text-right m-1"
                    >
                      {laborCategory.costByHours
                        ? utils.formatCurrency(laborCategory.costHoursTotal)
                        : "-"}
                    </div>
                  </td>
                  <td className="py-0" width={"10%"}>
                    <Input
                      className="form-control border text-right m-1 hide-arrows"
                      type={evaOverrideContext.editEnabled ? "number" : "text"}
                      step="0.1"
                      value={
                        evaOverrideContext.editEnabled
                          ? laborCategory.actualHours
                          : utils.formatHour(laborCategory.actualHours)
                      }
                      readOnly={!evaOverrideContext.editEnabled}
                      onChange={(evt) =>
                        setEvaOverrideContext({
                          action: EVA_OVERRIDE_ACTIONS.UPDATE_ITEM,
                          payload: {
                            id: laborCategory.id,
                            key: "laborCategories",
                            value: evt.currentTarget.value,
                          },
                        })
                      }
                    />
                  </td>
                  <td className="py-0" width={"15%"}>
                    <div
                      readOnly
                      className="form-control border text-right m-1"
                    >
                      {utils.formatCurrency(laborCategory.totalCost)}
                    </div>
                  </td>
                  <td className="py-0" width={"15%"}>
                    <div
                      readOnly
                      className={`${
                        difference < 0 ? "text-danger" : "text-success"
                      } form-control border text-right m-1 font-weight-bold`}
                    >
                      {utils.formatHour(difference)}
                    </div>
                  </td>
                </tr>
              );
            })}
          <tr className="text-muted bg-lighter">
            <th width={"40%"} className="align-middle">
              Total
            </th>
            <td className="py-0" width={"10%"}>
              <div readOnly className="form-control border text-right m-1">
                {utils.formatHour(estimatedHoursTotal)}
              </div>
            </td>
            <td className="py-0" width={"10%"}>
              <div readOnly className="form-control border text-right m-1">
                {utils.formatCurrency(
                  estimatedHoursTotal
                    ? actualTotals.totalCostEstimated
                    : 0
                )}
              </div>
            </td>
            <td className="py-0" width={"10%"}>
              <div readOnly className="form-control border text-right m-1">
                {utils.formatHour(actualTotals.actualHours)}
              </div>
            </td>
            <td className="py-0" width={"15%"}>
              <div readOnly className="form-control border text-right m-1">
                {utils.formatCurrency(actualTotals.totalCost)}
              </div>
            </td>
            <td className="py-0" width={"15%"}>
              <div
                readOnly
                className={`form-control border text-right m-1 font-weight-bold ${
                  actualTotals.difference < 0 ? "text-danger" : "text-success"
                }`}
              >
                {utils.formatHour(actualTotals.difference)}
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default EstimatedActualLaborSection;
