import classnames from "classnames";
import React, { useState } from "react";
import { utils } from "../../../../utils/utils";
import InformationModal from "../../../InformationModal";
import { awsApi } from "../../../../services/awsServices";
import ConfirmationModal from "../../../ConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumericFormat from "../../../NumericFormat";
import { useJobDetails } from "../../../../providers/jobDetailsProvider";
import { faPlus, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
} from "reactstrap";
import { orderAcknowledgmentApi } from "../../../../services/orderAcknowledgmentServices";
import QuoteItemsList from "./QuoteItemsList";
import OrderAcknowledgmentAttachment from "./OrderAcknowledgmentAttachment";
import JobSummaryReport from "../reports/JobSummaryReport";
import EditShipToModal from "./EditShipToModal";

const OrderAcknowledgmentEdition = ({
  orderAcknowledgment,
  onClose,
  archiveOrderAcknowledgment,
}) => {
  const [jobDetails] = useJobDetails();
  const attachmentsNumber = orderAcknowledgment.attachments.length;

  const [localOrderAcknowledgment, setLocalOrderAcknowledgment] = useState({
    ...orderAcknowledgment,
  });
  orderAcknowledgment.amount = utils.formatDecimal(orderAcknowledgment.amount);
  const [amount, setAmount] = useState(orderAcknowledgment.amount);
  const [pO, setPO] = useState(orderAcknowledgment.pO);
  const [salesTax, setSalesTax] = useState(orderAcknowledgment.salesTax);
  const [notes, setNotes] = useState(orderAcknowledgment.notes);
  const [attachments, setAttachments] = useState([]);
  const [attachmentSelected, setAttachmentSelected] =
    useState(attachmentsNumber);
  const [attachmentsToDelete, setAttachmentsToDelete] = useState([]);
  const [quoteItems, setQuoteItems] = useState(
    orderAcknowledgment.quoteItems.map((quoteItem) => {
      quoteItem.id = quoteItem.quoteItemId;
      return quoteItem;
    })
  );

  const ordersAcknowledgmentNumber = `${jobDetails.job.id}.${orderAcknowledgment.number}`;

  const [informationModal, setInformationModal] = useState(
    utils.initInformationModal
  );
  const [confirmationModal, setConfirmationModal] = useState(
    utils.initConfirmationModal
  );
  const [editShipToModal, setEditShipToModal] = useState();

  const onConfirm = async () => {
    if (attachments && attachments.length) {
      const attachmentsUploaded = await uploadAttachments();
      if (attachmentsUploaded) {
        await updateOrderAcknowledgment(attachmentsUploaded);
      }
    } else {
      await updateOrderAcknowledgment(null);
    }
  };

  const updateOrderAcknowledgment = async (attachments) => {
    await orderAcknowledgmentApi.update({
      ...localOrderAcknowledgment,
      amount,
      pO,
      salesTax,
      notes,
      quoteItems,
      attachments,
      attachmentsToDelete: attachmentsToDelete.length
        ? attachmentsToDelete
        : null,
    });
    setConfirmationModal(utils.initConfirmationModal);
    setInformationModal({
      isOpen: true,
      title: "Order Acknowledgment Update",
      body: "Order Acknowledgment updated successfully",
      onClose: () => {
        onClear();
      },
    });
  };

  const uploadAttachments = async () => {
    try {
      const filesMapping = {};
      const attachmentsArray = attachments.map((file) => {
        filesMapping[file.name] = file;
        return {
          fileName: file.name,
        };
      });
      const attachmentUrls = await orderAcknowledgmentApi.uploadAttachments({
        attachments: attachmentsArray,
      });
      for (let index = 0; index < attachmentUrls.length; index++) {
        const dataToUpload = {
          fileUrl: attachmentUrls[index].url,
          url: attachmentUrls[index].signedRequest,
          file: (attachmentUrls[index].file =
            filesMapping[attachmentUrls[index].fileName]),
        };
        attachmentUrls[index].file =
          filesMapping[attachmentUrls[index].fileName];
        await awsApi.putDocumentsToS3(dataToUpload);
      }
      return attachmentUrls;
    } catch (error) {
      setConfirmationModal(utils.initConfirmationModal);
      setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error to upload attachements.",
        onClose: () => {
          setInformationModal(utils.initInformationModal);
        },
      });
    }
  };

  const doSubmit = async (e) => {
    e.preventDefault();
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        try {
          await onConfirm();
        } catch (err) {
          setConfirmationModal(utils.initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Error",
            body: "There was an error with your request.",
            onClose: () => setInformationModal(utils.initInformationModal),
          });
        }
      },
      onClose: () => {
        setConfirmationModal(utils.initConfirmationModal);
      },
      title: "Order Acknowledgment Update",
      body: `
        <div class="text-center">
          Are you sure you you want to update Order Acknowledgment: ${ordersAcknowledgmentNumber}?
        </div>
      `,
      confirmColor: "warning",
    });
  };

  const onClear = () => {
    setAmount("");
    setPO("");
    setInformationModal(utils.initInformationModal);
    setConfirmationModal(utils.initConfirmationModal);
    onClose();
  };

  const removeAttachment = (attachment) => {
    orderAcknowledgment.attachments = orderAcknowledgment.attachments.filter(
      (file) => file.id !== attachment.id
    );
    const attachments = [...attachmentsToDelete];
    attachments.push(attachment);
    setAttachmentsToDelete(attachments);
  };

  return (
    <div>
      <Row>
        <h4 className="w-100 text-center">Edit Order Acknowledgment</h4>
        <h4 className="w-100 mb-2">Order# {ordersAcknowledgmentNumber}</h4>
        <QuoteItemsList
          quoteItemsSelected={quoteItems}
          setQuoteItemsSelected={setQuoteItems}
          setContractAmount={setAmount}
        />
        <Form onSubmit={doSubmit} className="w-100">
          <Row className="mt-2">
            <Col className="col-4 p-0">
              <FormGroup className="col-12">
                <Label for="pO">
                  <span>Customer PO#</span>
                  <span className="text-danger ml-2">*</span>
                </Label>
                <Input
                  required={true}
                  maxLength="50"
                  className="height-40p border"
                  placeholder="Enter PO#..."
                  type="text"
                  name="pO"
                  value={pO || ""}
                  onChange={(e) => setPO(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col className="col-4 p-0">
              <FormGroup className="col-12">
                <Label for="amount">
                  <span>Contract Amount</span>
                  <span className="text-danger ml-2">*</span>
                </Label>
                <NumericFormat
                  required={true}
                  maxLength={20}
                  allowNegative={false}
                  decimalScale={2}
                  name="amount"
                  className="height-40p border rounded form-control"
                  value={amount || ""}
                  placeholder={"Enter the amount..."}
                  thousandSeparator={true}
                  prefix={"$"}
                  onValueChange={(e) => setAmount(e.value)}
                />
              </FormGroup>
            </Col>
            <Col className="col-4 p-0">
              <FormGroup className="col-12">
                <Label for="salesTax">
                  <span>Sales Tax</span>
                </Label>
                <NumericFormat
                  maxLength={20}
                  allowNegative={false}
                  decimalScale={2}
                  name="salesTax"
                  className="height-40p border rounded form-control"
                  value={salesTax || 0}
                  thousandSeparator={true}
                  suffix={"%"}
                  onValueChange={(e) => setSalesTax(e.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <FormGroup className="d-flex flex-column mt-2">
                <Label for="notes">
                  <span>Notes/Clarifications/Exclusions</span>
                </Label>
                <Input
                  className=" border"
                  placeholder="Enter Notes/Clarifications/Exclusions..."
                  type="textarea"
                  rows={7}
                  maxLength="4096"
                  name="notes"
                  value={notes || ""}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="w-100 m-0 mb-3 d-flex align-items-center justify-content-between">
            <div className="d-flex flex-column">
              <h5 className="m-0 w-100 font-weight-normal">Attachments</h5>
              <div className="bg-secondary text-white mt-2 rounded">
                <Label
                  for="attachments"
                  className="m-0 px-2 py1 font-size-75 cursor-pointer"
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="ml-2">Upload Docs</span>
                </Label>
                <Input
                  className="d-none"
                  type="file"
                  id="attachments"
                  name="attachments"
                  multiple
                  accept=".pdf"
                  onChange={(e) => {
                    const currentAttachments = attachments;
                    const newAttachments = Array.from(e.target.files);
                    setAttachments(currentAttachments.concat(newAttachments));
                  }}
                />
              </div>
            </div>
            <Button
              size="sm"
              className="ml-2 rounded"
              type="button"
              onClick={() => setEditShipToModal(true)}
            >
              Edit Ship To
            </Button>
          </Row>
          <Row>
            <ListGroup className="w-100">
              {attachments.map((file, index) => (
                <ListGroupItem
                  className="border-0 pt-0 pb-2 d-flex align-items-start"
                  key={`attachments-${index}`}
                >
                  <span className="mr-2">{file.name}</span>
                  <FontAwesomeIcon
                    size="lg"
                    icon={faWindowClose}
                    className="align-self-center text-danger rounded cursor-pointer"
                    onClick={() => {
                      const newAttachments = attachments.filter(
                        (attachment) => attachment.name !== file.name
                      );
                      setAttachments(newAttachments);
                    }}
                  />
                </ListGroupItem>
              ))}
            </ListGroup>
          </Row>
          {orderAcknowledgment.attachments ? (
            <Row>
              <Nav
                tabs
                className="d-flex text-center flex-nowrap job-details-nav cursor-pointer mt-2 w-100"
              >
                <div className="d-flex flex-grow-1">
                  {orderAcknowledgment.attachments.map((attachment, index) => (
                    <NavItem key={`nav-${index}`}>
                      <NavLink
                        className={classnames({
                          active: attachmentSelected === index,
                        })}
                        onClick={() => setAttachmentSelected(index)}
                      >
                        {attachment.name}
                      </NavLink>
                    </NavItem>
                  ))}
                  <NavItem key={`nav-${attachmentsNumber}`}>
                    <NavLink
                      className={classnames({
                        active: attachmentSelected === attachmentsNumber,
                      })}
                      onClick={() => setAttachmentSelected(attachmentsNumber)}
                    >
                      Sales Order PDF
                    </NavLink>
                  </NavItem>
                </div>
              </Nav>
              <TabContent activeTab={attachmentSelected} className="w-100">
                {orderAcknowledgment.attachments.map((attachment, index) => (
                  <TabPane tabId={index} key={`pane-${index}`}>
                    <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
                      <CardBody>
                        {attachmentSelected === index ? (
                          <Row>
                            <OrderAcknowledgmentAttachment
                              attachment={attachment}
                              onDelete={removeAttachment}
                            />
                          </Row>
                        ) : null}
                      </CardBody>
                    </Card>
                  </TabPane>
                ))}
                <TabPane tabId={attachmentsNumber}>
                  <Card className="border-radius-0 box-shadow-none border-left border-bottom border-right">
                    <CardBody>
                      <JobSummaryReport
                        orderAcknowledgmentId={orderAcknowledgment.id}
                      />
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
            </Row>
          ) : null}
          <Row className="d-flex justify-content-between">
            <div className="flex-grow-1 pr-2">
              <Button
                className="w-100"
                color={"secondary"}
                type="submit"
                disabled={!pO || !amount || !quoteItems.length}
              >
                Save
              </Button>
            </div>
            <div className="flex-grow-1 pl-2">
              <Button
                className="w-100"
                color={`${orderAcknowledgment.archived ? "warning" : "danger"}`}
                onClick={() => {
                  archiveOrderAcknowledgment({
                    ...orderAcknowledgment,
                    archived: !orderAcknowledgment.archived,
                  });
                }}
              >
                {orderAcknowledgment.archived ? "Unarchive" : "Archive"}
              </Button>
            </div>
          </Row>
        </Form>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal(utils.initInformationModal)
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : editShipToModal ? (
        <EditShipToModal
          orderAcknowledgment={localOrderAcknowledgment}
          onClose={() => setEditShipToModal()}
          onSubmit={(orderAcknowledgment) => {
            setEditShipToModal();
            setLocalOrderAcknowledgment({ ...orderAcknowledgment });
            setInformationModal({
              isOpen: true,
              title: "Edit Ship To Address",
              body: "Remember to save the order acknowledgment for this change to take effect.",
            });
          }}
        />
      ) : null}
    </div>
  );
};

export default OrderAcknowledgmentEdition;
